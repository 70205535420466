<template>
	<v-sheet class="asset" id="asset" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="6" class="my-auto">
				<h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
			</v-col>
			<v-col md="6" class="text-right">
				<v-btn
					v-if="stepper > 1"
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading"
					v-on:click="goBackStepper()"
				>
					Back
				</v-btn>
				<v-btn
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					v-on:click="updateOrCreate()"
				>
					Save <template v-if="stepper < 3">&amp; Next</template>
				</v-btn>
				<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
			</v-col>
			<v-col md="12">
				<v-stepper flat tile class="item-group-stepper" v-model="stepper">
					<v-stepper-header>
						<v-stepper-step class="py-2 btx-label" :complete="stepper > 1" step="1">
							Overview
						</v-stepper-step>

						<v-divider></v-divider>

						<v-stepper-step class="py-2 btx-label" :complete="stepper > 2" step="2">
							Warranty Details
						</v-stepper-step>

						<v-divider></v-divider>

						<v-stepper-step class="py-2 btx-label" :complete="stepper > 3" step="3">
							Maintenance Details
						</v-stepper-step>
					</v-stepper-header>

					<v-stepper-items>
						<v-form
							ref="assetForm"
							v-model.trim="formValid"
							lazy-validation
							v-on:submit.stop.prevent="updateOrCreate"
						>
							<v-stepper-content class="pt-0" step="1">
								<perfect-scrollbar
									:options="{ suppressScrollX: true }"
									class="scroll custom-box-top-inner-shadow"
									style="max-height: calc(100vh - 185px); position: relative"
								>
									<v-row>
										<v-col md="8">
											<perfect-scrollbar
												:options="{ suppressScrollX: true }"
												class="scroll custom-box-top-inner-shadow"
												style="max-height: calc(100vh - 160px); position: relative"
											>
												<v-row>
													<v-col md="12" class="py-0">
														<p class="middle-header-background mb-4 px-4">
															Core Information<br />
															<span>
																<v-icon small>mdi-progress-question</v-icon> Specify the primary description for
																your asset</span
															>
														</p>
													</v-col>
												</v-row>
												<v-row class="px-4">
													<v-col md="3" class="my-auto py-0">
														<label for="name" class="btx-label mt-2 required">Name</label>
													</v-col>
													<v-col md="9" class="py-0">
														<TextInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="name"
															:rules="[vrules.required(asset.name, 'Name')]"
															:class="{
																required: !asset.name,
															}"
															placeholder="Name"
															v-model="asset.name"
														></TextInput>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="name" class="btx-label mt-2">Pico Barcode</label>
													</v-col>
													<v-col md="9" class="py-0">
														<TextInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="pico-barcode"
															placeholder="Pico Barcode"
															v-model="asset.pico_barcode"
														></TextInput>
													</v-col>
													<template v-if="false">
														<v-col md="3" class="my-auto py-0">
															<label for="model" class="btx-label mt-2 required">Model Number</label>
														</v-col>
														<v-col md="9" class="py-0">
															<TextInput
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																id="model"
																placeholder="Model Number"
																v-model="asset.model"
																:rules="[vrules.required(asset.model, 'Model')]"
																:class="{
																	required: !asset.model,
																}"
															></TextInput>
														</v-col>
													</template>
													<v-col md="3" class="my-auto py-0">
														<label for="group" class="btx-label mt-2 required">Category</label>
													</v-col>
													<v-col md="9" class="py-0">
														<AutoCompleteInput
															hide-details
															:items="categoryList"
															:disabled="pageLoading"
															:loading="pageLoading"
															id="group"
															:rules="[vrules.required(asset.group, 'Group')]"
															:class="{
																required: !asset.group,
															}"
															v-on:change="getSubGroup($event)"
															placeholder="Category"
															v-model="asset.group"
															append-outer-icon="mdi-cog"
															v-on:click:append-outer="manageCategoryDialog = true"
														></AutoCompleteInput>
													</v-col>
													<template v-if="false && subGroupList.length">
														<v-col md="3" class="my-auto py-0">
															<label for="sub-group" class="btx-label mt-2">Sub Group</label>
														</v-col>
														<v-col md="9" class="py-0">
															<AutoCompleteInput
																hide-details
																:items="subGroupList"
																:disabled="pageLoading"
																:loading="pageLoading"
																id="sub-group"
																placeholder="Sub Group"
																v-model="asset.sub_group"
															></AutoCompleteInput>
														</v-col>
													</template>
													<template v-if="false">
														<v-col md="3" class="my-auto py-0">
															<label for="id-number" class="btx-label mt-2"
																>Identification Number
																<TooltipQuestion>
																	<template v-slot:text
																		>Your unique identifier for this<br />Asset. It can also be printed<br />and
																		scanned as a barcode.</template
																	>
																</TooltipQuestion>
															</label>
														</v-col>
														<v-col md="9" class="py-0">
															<TextInput
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																id="id-number"
																placeholder="Identification Number"
																v-model="asset.id_number"
															></TextInput>
														</v-col>
													</template>
													<v-col md="3" class="py-0">
														<label for="description" class="btx-label mt-2 required">Description</label>
													</v-col>
													<v-col md="9" class="py-0">
														<TextAreaInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="description"
															placeholder="Description"
															v-model="asset.description"
															:rules="[vrules.required(asset.description, 'Description')]"
															:class="{
																required: !asset.description,
															}"
														></TextAreaInput>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="contact" class="btx-label mt-2">Contact for Item</label>
													</v-col>
													<v-col md="9" class="py-0">
														<PhoneInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="contact"
															placeholder="Contact for Item"
															v-model="asset.contact"
														></PhoneInput>
													</v-col>
												</v-row>
												<v-row>
													<v-col md="12" class="py-0">
														<p class="middle-header-background my-4 mt-7 px-4">
															Extended Information<br />
															<span>
																<v-icon small>mdi-progress-question</v-icon> Specify the purchase and location
																details for your asset</span
															>
														</p>
													</v-col>
												</v-row>
												<v-row class="px-4">
													<v-col md="3" class="my-auto py-0">
														<label for="cost-price" class="btx-label mt-2 required">Cost Price</label>
													</v-col>
													<v-col md="9" class="py-0">
														<PriceInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="cost-price"
															placeholder="Cost Price"
															v-model="asset.cost_price"
															:rules="[vrules.required(asset.cost_price, 'Cost Price')]"
															:class="{
																required: !asset.cost_price,
															}"
														></PriceInput>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="vendor" class="btx-label mt-2 required">Supplier</label>
													</v-col>
													<v-col md="9" class="py-0">
														<AutoCompleteInput
															hide-details
															:items="vendorList"
															:disabled="pageLoading"
															:loading="pageLoading"
															id="vendor"
															placeholder="Supplier"
															v-model="asset.vendor"
															:rules="[vrules.required(asset.vendor, 'Vendor')]"
															:class="{
																required: !asset.vendor,
															}"
														></AutoCompleteInput>
													</v-col>
													<template v-if="false">
														<v-col md="3" class="my-auto py-0">
															<label for="vendor" class="btx-label mt-2">Incharge Officer</label>
														</v-col>
														<v-col md="9" class="py-0">
															<AutoCompleteInput
																hide-details
																:items="memberList"
																:disabled="pageLoading"
																:loading="pageLoading"
																id="vendor"
																placeholder="Incharge Officer"
																v-model="asset.member"
															></AutoCompleteInput>
														</v-col>
													</template>
													<template v-if="false">
														<v-col md="3" class="my-auto py-0">
															<label for="location" class="btx-label mt-2 required">Location</label>
														</v-col>
														<v-col md="9" class="py-0">
															<AutoCompleteInput
																hide-details
																:items="locationList"
																:disabled="pageLoading"
																:loading="pageLoading"
																id="location"
																placeholder="Location"
																v-model="asset.location"
																:rules="[vrules.required(asset.name, 'Name')]"
																:class="{
																	required: !asset.name,
																}"
															></AutoCompleteInput>
														</v-col>
													</template>
													<v-col md="3" class="my-auto py-0">
														<label for="purchased-on" class="btx-label mt-2 required">Purchased On</label>
													</v-col>
													<v-col md="9" class="py-0">
														<DatePicker
															hide-details
															clearable
															:disabled="pageLoading"
															:loading="pageLoading"
															id="purchased-on"
															placeholder="Purchased On"
															v-model="asset.purchased_on"
															:rules="[vrules.required(asset.purchased_on, 'Purchased On')]"
															:class="{
																required: !asset.purchased_on,
															}"
														></DatePicker>
													</v-col>
													<v-col md="3" class="py-0">
														<label for="attachment" class="btx-label mt-2">Attachment</label>
													</v-col>
													<v-col md="9" class="py-0">
														<v-file-input
															v-model="attachment"
															color="blue darken-4"
															small-chips
															placeholder="Click here to select files"
															multiple
															prepend-icon=""
															class="ctx-attachment-field mt-3 pt-0"
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															prepend-inner-icon="mdi-attachment mdi-rotate-315"
															outlined
															v-on:change="limitAttachment($event)"
														></v-file-input>
														<span class="font-small">Max 3 files allowed.</span>
													</v-col>
													<v-col md="3" class="py-0">
														<label for="remark" class="btx-label mt-2">Remark</label>
													</v-col>
													<v-col md="9" class="py-0">
														<TextAreaInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="remark"
															placeholder="Remark"
															v-model="asset.remark"
														></TextAreaInput>
													</v-col>
													<v-col md="12" class="min-height-40"> </v-col>
												</v-row>
											</perfect-scrollbar>
										</v-col>
										<v-col md="4">
											<v-row>
												<v-col md="12" class="my-6 py-0 text-center">
													<ImageUpload
														can-change
														v-model="asset.image"
														:page-loading="pageLoading"
													></ImageUpload>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</perfect-scrollbar>
							</v-stepper-content>
							<v-stepper-content class="pt-0" step="2">
								<perfect-scrollbar
									:options="{ suppressScrollX: true }"
									class="scroll custom-box-top-inner-shadow"
									style="max-height: calc(100vh - 185px); position: relative"
								>
									<v-row>
										<v-col md="9">
											<perfect-scrollbar
												:options="{ suppressScrollX: true }"
												class="scroll custom-box-top-inner-shadow"
												style="max-height: calc(100vh - 160px); position: relative"
											>
												<v-row>
													<v-col md="12" class="py-0">
														<p class="middle-header-background my-4 mt-7 px-4">
															Warranty Information<br />
															<span>
																<v-icon small>mdi-progress-question</v-icon> Specify the warranty details for your
																asset</span
															>
														</p>
													</v-col>
												</v-row>
												<v-row class="px-4">
													<v-col md="3" class="my-auto py-0">
														<label for="cost-price" class="btx-label mt-2 required">Start Date</label>
													</v-col>
													<v-col md="9" class="py-0">
														<DatePicker
															hide-details
															clearable
															:disabled="pageLoading"
															:loading="pageLoading"
															id="warranty-start-date"
															placeholder="Start Date"
															v-model="asset.warranty_start_date"
															:rules="[vrules.required(asset.warranty_start_date, 'Warranty Start Date')]"
															:class="{
																required: !asset.warranty_start_date,
															}"
														></DatePicker>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="vendor" class="btx-label mt-2 required">End Date</label>
													</v-col>
													<v-col md="9" class="py-0">
														<DatePicker
															hide-details
															clearable
															:disabled="pageLoading"
															:loading="pageLoading"
															id="warranty-end-date"
															placeholder="End Date"
															:min-date="asset.warranty_start_date"
															v-model="asset.warranty_end_date"
															:rules="[vrules.required(asset.warranty_end_date, 'Warranty End Date')]"
															:class="{
																required: !asset.warranty_end_date,
															}"
														></DatePicker>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="location" class="btx-label mt-2 required">Reminder Date</label>
													</v-col>
													<v-col md="9" class="py-0">
														<DatePicker
															hide-details
															clearable
															:disabled="pageLoading"
															:loading="pageLoading"
															id="warranty-reminder-date"
															placeholder="Reminder Date"
															v-model="asset.warranty_reminder_date"
															:min-date="asset.warranty_start_date"
															:max-date="asset.warranty_end_date"
															:rules="[vrules.required(asset.warranty_reminder_date, 'Warranty Reminder Date')]"
															:class="{
																required: !asset.warranty_reminder_date,
															}"
														></DatePicker>
													</v-col>
													<v-col md="12" class="min-height-40"> </v-col>
												</v-row>
											</perfect-scrollbar>
										</v-col>
									</v-row>
								</perfect-scrollbar>
							</v-stepper-content>
							<v-stepper-content class="pt-0" step="3">
								<perfect-scrollbar
									:options="{ suppressScrollX: true }"
									class="scroll custom-box-top-inner-shadow"
									style="max-height: calc(100vh - 185px); position: relative"
								>
									<v-row>
										<v-col md="9">
											<perfect-scrollbar
												:options="{ suppressScrollX: true }"
												class="scroll custom-box-top-inner-shadow"
												style="max-height: calc(100vh - 160px); position: relative"
											>
												<v-row>
													<v-col md="12" class="py-0">
														<p class="middle-header-background my-4 mt-7 px-4">
															Maintenance Information<br />
															<span>
																<v-icon small>mdi-progress-question</v-icon> Specify the maintenance details for
																your asset</span
															>
														</p>
													</v-col>
												</v-row>
												<v-row class="px-4">
													<v-col md="3" class="my-auto py-0">
														<label for="cost-price" class="btx-label mt-2">Start Date</label>
													</v-col>
													<v-col md="9" class="py-0">
														<DatePicker
															hide-details
															clearable
															:disabled="pageLoading"
															:loading="pageLoading"
															id="maintenance-start-date"
															placeholder="Start Date"
															v-model="asset.maintenance_start_date"
														></DatePicker>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="vendor" class="btx-label mt-2">End Date</label>
													</v-col>
													<v-col md="9" class="py-0">
														<DatePicker
															hide-details
															clearable
															:disabled="pageLoading"
															:loading="pageLoading"
															id="maintenance-end-date"
															placeholder="End Date"
															:min-date="asset.maintenance_start_date"
															v-model="asset.maintenance_end_date"
														></DatePicker>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="location" class="btx-label mt-2">Reminder Date</label>
													</v-col>
													<v-col md="9" class="py-0">
														<DatePicker
															hide-details
															clearable
															:disabled="pageLoading"
															:loading="pageLoading"
															id="maintenance-reminder-date"
															placeholder="Reminder Date"
															:min-date="asset.maintenance_start_date"
															:max-date="asset.maintenance_end_date"
															v-model="asset.maintenance_reminder_date"
														></DatePicker>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="cost-price" class="btx-label mt-2">Maintenance Cost Price</label>
													</v-col>
													<v-col md="9" class="py-0">
														<PriceInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="cost-price"
															placeholder="Cost Price"
															v-model="asset.maintenance_cost"
														></PriceInput>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="vendor" class="btx-label mt-2">Maintenance By</label>
													</v-col>
													<v-col md="9" class="py-0">
														<AutoCompleteInput
															hide-details
															:items="vendorList"
															:disabled="pageLoading"
															:loading="pageLoading"
															id="vendor"
															placeholder="Supplier"
															v-model="asset.maintenance_by"
														></AutoCompleteInput>
													</v-col>
													<v-col md="12" class="min-height-40"> </v-col>
												</v-row>
											</perfect-scrollbar>
										</v-col>
									</v-row>
								</perfect-scrollbar>
							</v-stepper-content>
						</v-form>
					</v-stepper-items>
				</v-stepper>
			</v-col>
		</v-row>
		<template v-if="manageCategoryDialog">
			<ManageCategory
				:dialog="manageCategoryDialog"
				:category="categoryList"
				v-on:close-dialog="manageCategoryDialog = false"
				v-on:success="getCategories"
			></ManageCategory>
		</template>
	</v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { CreateAsset, UpdateAsset, GetAsset } from "@/core/lib/asset.lib";
import { UploadFiles } from "@/core/lib/upload.lib";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TextInput from "@/view/components/TextInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import PhoneInput from "@/view/components/Phone";
import PriceInput from "@/view/components/PriceInput";
import DatePicker from "@/view/components/DatePicker";
import ImageUpload from "@/view/components/ImageUpload";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import { filter, toString, map } from "lodash";
import ManageCategory from "@/view/components/Manage-Category.vue";

export default {
	name: "asset-create",
	title: "Create Asset",
	data() {
		return {
			uuid: null,
			duplicateUUID: null,
			barcode: null,
			manageCategoryDialog: false,
			stepper: 1,
			formValid: true,
			pageLoading: false,
			attachment: [],
			categoryList: [],
			subGroupList: [],
			allSubGroupList: [],
			vendorList: [],
			memberList: [],
			locationList: [],
			asset: {
				name: null,
				model: null,
				pico_barcode: null,
				member: null,
				group: null,
				sub_group: null,
				id_number: null,
				description: null,
				remark: null,
				contact: null,
				cost_price: null,
				vendor: null,
				location: null,
				image: null,
				attachment: [],
				purchased_on: null,
				warranty_start_date: null,
				warranty_end_date: null,
				warranty_reminder_date: null,
				maintenance_start_date: null,
				maintenance_end_date: null,
				maintenance_reminder_date: null,
				maintenance_by: null,
				maintenance_cost: null,
			},
		};
	},
	components: {
		TextInput,
		PhoneInput,
		PriceInput,
		ImageUpload,
		DatePicker,
		TextAreaInput,
		ManageCategory,
		TooltipQuestion,
		AutoCompleteInput,
	},
	methods: {
		limitAttachment(output) {
			if (this.pageLoading) {
				return false;
			}

			this.attachment = output.slice(0, 3);

			if (this.attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < this.attachment.length; i++) {
				request.append(`files[${i}]`, this.attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.asset.attachment = map(response, "path");
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		async updateOrCreate() {
			const _this = this;

			if (_this.stepper < 3) {
				_this.stepper++;
				return false;
			}
			const formErrors = _this.validateForm(_this.$refs.assetForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.assetForm.validate()) {
				return false;
			}

			const formData = {
				name: _this.asset.name,
				model: _this.asset.model,
				contact: _this.asset.contact,
				id_number: _this.asset.id_number,
				description: _this.asset.description,
				attachment: _this.asset.attachment,
				group: _this.asset.group,
				sub_group: _this.asset.sub_group,
				cost_price: _this.asset.cost_price,
				remark: _this.asset.remark,
				supplier: _this.asset.vendor,
				image: _this.asset.image,
				location: _this.asset.location,
				purchased_on: _this.asset.purchased_on,
				member: _this.asset.member,
				pico_barcode: _this.asset.pico_barcode,
				warranty_start_date: _this.asset.warranty_start_date,
				warranty_end_date: _this.asset.warranty_end_date,
				warranty_reminder_date: _this.asset.warranty_reminder_date,
				maintenance_start_date: _this.asset.maintenance_start_date,
				maintenance_end_date: _this.asset.maintenance_end_date,
				maintenance_reminder_date: _this.asset.maintenance_reminder_date,
				maintenance_by: _this.asset.maintenance_by,
				maintenance_cost: _this.asset.maintenance_cost,
			};

			try {
				_this.pageLoading = true;

				if (_this.uuid) {
					const { uuid } = await UpdateAsset(_this.uuid, formData);

					_this.$router.replace({
						name: "asset-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Asset has been updated." },
					]);
				} else {
					const { uuid } = await CreateAsset(formData);

					_this.$router.replace({
						name: "asset-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Asset has been created." },
					]);
				}
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		getSubGroup(param) {
			this.subGroupList = filter(this.allSubGroupList, { group: param });
		},
		getAsset() {
			GetAsset(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.asset.name = data.name;
					this.asset.model = data.model;
					this.asset.group = data.group;
					this.asset.sub_group = data.sub_group;
					this.asset.id_number = data.id_number;
					this.asset.description = data.description;
					this.asset.contact = data.contact;
					this.asset.cost_price = data.cost_price;
					this.asset.vendor = data.supplier;
					this.asset.location = data.location;
					this.asset.image = data.image;
					this.asset.remark = data.remark;
					this.asset.purchased_on = data.purchased_on;
					this.asset.pico_barcode = data.pico_barcode;
					this.asset.member = data.member;
					this.asset.warranty_start_date = data.warranty_start_date;
					this.asset.warranty_end_date = data.warranty_end_date;
					this.asset.warranty_reminder_date = data.warranty_reminder_date;
					this.asset.maintenance_start_date = data.maintenance_start_date;
					this.asset.maintenance_end_date = data.maintenance_end_date;
					this.asset.maintenance_reminder_date = data.maintenance_reminder_date;
					this.asset.maintenance_by = data.maintenance_by;
					this.asset.maintenance_cost = data.maintenance_cost;

					console.log(this.asset);

					this.$nextTick(() => {
						if (this.asset.group) {
							this.getSubGroup(this.asset.group);
						}
					});

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Asset", disabled: true },
						{ text: "Update", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getDuplicateAsset() {
			GetAsset(this.duplicateUUID)
				.then((data) => {
					this.barcode = data.barcode;
					this.asset.name = data.name;
					this.asset.model = data.model;
					this.asset.group = data.group;
					this.asset.sub_group = data.sub_group;
					this.asset.id_number = data.id_number;
					this.asset.description = data.description;
					this.asset.contact = data.contact;
					this.asset.cost_price = data.cost_price;
					this.asset.vendor = data.supplier;
					this.asset.location = data.location;
					this.asset.image = data.image;
					this.asset.remark = data.remark;
					this.asset.purchased_on = data.purchased_on;
					this.asset.pico_barcode = data.pico_barcode;
					this.asset.member = data.member;
					this.asset.warranty_start_date = data.warranty_start_date;
					this.asset.warranty_end_date = data.warranty_end_date;
					this.asset.warranty_reminder_date = data.warranty_reminder_date;
					this.asset.maintenance_start_date = data.maintenance_start_date;
					this.asset.maintenance_end_date = data.maintenance_end_date;
					this.asset.maintenance_reminder_date = data.maintenance_reminder_date;
					this.asset.maintenance_by = data.maintenance_by;
					this.asset.maintenance_cost = data.maintenance_cost;

					this.$nextTick(() => {
						if (this.asset.group) {
							this.getSubGroup(this.asset.group);
						}
					});

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Asset", disabled: true },
						{ text: "Create", disabled: true },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		pageTitle() {
			if (this.uuid) {
				return this.asset.name;
			}
			return "Create new Asset";
		},
		getCategories(data) {
			this.categoryList = data;
		},
		initialize() {
			this.categoryList = this.localDB("groups", []);
			this.allSubGroupList = this.localDB("sub_groups", []);
			this.vendorList = this.localDB("suppliers", []);
			this.memberList = this.localDB("members", []);
			this.locationList = this.localDB("locations", []);
		},
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
	mounted() {
		this.initialize();

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Asset", disabled: true },
			{ text: "Create", disabled: true },
		]);

		const { name, params, query } = this.$route;

		this.duplicateUUID = toString(query.duplicate);

		if (query && query.supplier) {
			this.asset.vendor = query.supplier;
		}
		if (query && query.group) {
			this.asset.group = query.group;
		}

		if (name === "asset-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getAsset();
		} else if (this.duplicateUUID) {
			this.pageLoading = true;
			this.getDuplicateAsset();
		}
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
